<template>
    <div class="page formPage" id="releaseActivities">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" :disabled="editOrNot==0"
                 class="query-form">
            <div class="biaodan">
                <div class="left">
                    <div class="xiao">
                        <el-form-item label="活动名称" prop="name">
                            <el-col :span="12">
                                <el-input size="small" clearable v-model="ruleForm.name" maxlength="50"
                                          placeholder="请输入活动名称（限50字）"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="活动类型" prop="convention">
                            <el-radio-group v-model="ruleForm.convention" @input="changeServiceType">
                                <el-radio label="0">主题活动</el-radio>
                                <el-radio label="1">常规活动</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="活动类别" prop="actiServiceObj">
                            <el-radio v-model="ruleForm.actiServiceObj" :label="item.value"
                                      v-for="item in $dictUtils.getDictList('actiServiceObj')" :key="item.value">
                                {{item.label}}
                            </el-radio>
                        </el-form-item>
                        <el-form-item v-if="ruleForm.actiType != '2'" label="活动地点" prop="address">
                            <el-col :span="12">
                                <el-input size="small" maxlength="90" clearable v-model="ruleForm.address"
                                          placeholder="请输入活动地点（限90字）"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="活动图片" prop="imgUrl">
                            <ImgUpload :imgAskObj="uploadRequire"
                                       @getImgList="getImgList"
                            ></ImgUpload>
                        </el-form-item>
                    </div>
                </div>
                <div class="left">
                    <div class="xiao">
                        <el-form-item label="置顶" prop="top">
                            <el-input-number size="small" @change="handleChangeTop()" v-model="ruleForm.top" :min="1"
                                             :max="999"
                                             label="描述文字"></el-input-number>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="data">
                <div :class="[index == 0 ? 'list2' : 'list']" v-for="(item,index) in ruleForm.activityCourseSession"
                     :key="index">
                    <el-row :gutter="10">
                        <el-col :span="13">
                            <el-form-item label="活动时间" :prop="`activityCourseSession.${index}.actuvutyTotall`"
                                          :rules="[{ required: true, message: '请选择活动时间', trigger: 'blur' }]">
                                <el-date-picker v-if="ruleForm.convention == 0"
                                                size="small"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                v-model="item.actuvutyTotall"
                                                @change="activeTimePeriod(index, item)"
                                                type="datetimerange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期" :picker-options="pickerOptions"
                                                :disabled="actiState == '1' || actiState == '2' || actiState == '3'">
                                </el-date-picker>
                                <el-time-picker v-else size="small" @change="activeTimePeriod(index, item)"
                                                is-range value-format="HH:mm:ss" format="HH:mm:ss"
                                                v-model="item.actuvutyTotall"
                                                range-separator="至"
                                                start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                placeholder="选择时间范围">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="13">
                            <el-form-item label="预约时间" :prop="`activityCourseSession.${index}.appointmentPeriod`"
                                          :rules="[{ required: true, message: '请选择预约时间', trigger: 'blur' }]">
                                <el-date-picker v-if="ruleForm.convention == 0"
                                                size="small"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                v-model="item.appointmentPeriod"
                                                @change="activeappointmentPeriod(index, item)"
                                                type="datetimerange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期" :picker-options="pickerOptions"
                                                :disabled="actiState == '1' || actiState == '2' || actiState == '3'">
                                </el-date-picker>
                                <el-time-picker v-else size="small" @change="activeappointmentPeriod(index, item)"
                                                is-range value-format="HH:mm:ss" format="HH:mm:ss"
                                                v-model="item.appointmentPeriod"
                                                range-separator="至"
                                                start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                placeholder="选择时间范围">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-form-item label-width="0" required>
                            <el-col :span="8">
                                <el-form-item label="退票截止时间" :prop="`activityCourseSession.${index}.outTicketTime`"
                                              :rules="[{ required: true, message: '请选择退票截止时间', trigger: 'blur' }]">
                                    <el-date-picker v-if="ruleForm.convention == 0" style="width:100%"
                                                    size="small"
                                                    v-model="item.outTicketTime"
                                                    @change="outTicketTimeChange(item)"
                                                    value-format="yyyy-MM-dd HH:mm:ss"
                                                    format="yyyy-MM-dd HH:mm:ss"
                                                    type="datetime"
                                                    placeholder="退票截止时间" :picker-options="pickerOptions"
                                                    :disabled="item.appointmentPeriod == null || actiState == '1' || actiState == '2' || actiState == '3'">
                                    </el-date-picker>
                                    <el-time-picker v-else size="small" style="width:100%"
                                                    @change="outTicketTimeChange(item)"
                                                    value-format="HH:mm:ss"
                                                    format="HH:mm:ss"
                                                    v-model="item.outTicketTime"
                                                    placeholder="退票截止时间"
                                                    :disabled="item.appointmentPeriod == null || actiState == '1' || actiState == '2' || actiState == '3'">
                                    </el-time-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="16">
                                <el-col :span="9">
                                    <el-form-item clearable label="最少人数"  label-width="100px"
                                                  :prop="`activityCourseSession.${index}.minPeople`"
                                                  :rules="[{ required: true, message: '最少人数', trigger: 'blur' },
                                                  { pattern: /^[0-9]*[1-9][0-9]*$/, message: '最少人数必须为整数'}]">
                                        <el-input size="small" maxlength="3" style="width: 100%"
                                                  v-model="item.minPeople"
                                                  onpaste="return false;" @blur="peopleChange(item)"
                                                  :disabled="actiState == '1' || actiState == '2' || actiState == '3'"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="9">
                                    <el-form-item clearable label="最大人数"  label-width="100px"
                                                  :prop="`activityCourseSession.${index}.maxPeople`"
                                                  :rules="[{ required: true, message: '最大人数', trigger: 'blur' },
                                                  { pattern: /^[0-9]*[1-9][0-9]*$/, message: '最大人数必须为整数'}]">
                                        <el-input size="small" maxlength="3" style="width: 100%"
                                                  v-model="item.maxPeople"
                                                  onpaste="return false;" @blur="peopleChange(item)"
                                                  :disabled="actiState == '1' || actiState == '2' || actiState == '3'"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-form-item>
                    </el-row>
                    <div v-if="index != 0 && activityselect != 2  && activityselect != 3"
                         @click="deleteTimePeriod(index)" class="shanchuanniu pointer">×
                    </div>
                </div>
            </div>
            <div class="addanniu m_b2">
                <el-button v-if="ruleForm.actiType != '2' && activityselect != 2  && activityselect != 3"
                           @click="addTimePeriod" type="primary" size="small"
                           icon="el-icon-plus">增加时间段
                </el-button>
            </div>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="收费类型" prop="chargeType1">
                        <el-select :disabled="guanbishoufei || actiState == '1' || actiState == '2' || actiState == '3'"
                                   size="small" v-model="ruleForm.chargeType"
                                   placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item prop="price" label-width="0px">
                        <el-input clearable size="small" min="'0'" v-model="ruleForm.price"
                                  placeholder="价格"
                                  :disabled="shoufeileixin || actiState == '1' || actiState == '2' || actiState == '3'"
                                  maxlength="7" style="width: 70%"></el-input>
                        元/人
                    </el-form-item>
                </el-col>
                <el-col :span="3">
                    <el-form-item label="促销" label-width="60px">
                        <el-switch v-model="switchxuans"
                                   :disabled="shoufeileixin || actiState == '1' || actiState == '2' || actiState == '3'"
                                   @change="PromotionStatusChange"
                                   :active-value="'1'"
                                   :inactive-value="'0'"
                        ></el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="ruleForm.isPromotion != 0 && ruleForm.chargeType != 0">
                <el-col :span="24">
                    <el-form-item label="促销价格" prop="promotionPrice">
                        <el-input clearable size="small" min="0" v-model="ruleForm.promotionPrice"
                                  placeholder="价格"
                                  :disabled="shoufeileixin || actiState == '1' || actiState == '2' || actiState == '3'"
                                  style="width: 12%"></el-input>
                        元/人
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="促销时间" prop="promotionPeriod" :rules="[{ required: true, message: '请选择促销时间', trigger: 'change' }]">
                        <el-date-picker
                                style="width: 48%"
                                size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                :disabled="shoufeileixin || actiState == '1' || actiState == '2' || actiState == '3'"
                                v-model="ruleForm.promotionPeriod"
                                @change="activePromotionPeriod"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期" :picker-options="pickerOptions">
                        </el-date-picker>
                        <!--<el-time-picker v-else size="small" @change="activePromotionPeriod"
                                        is-range value-format="HH:mm:ss" format="HH:mm:ss"
                                        v-model="ruleForm.promotionPeriod"
                                        range-separator="至"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        placeholder="选择时间范围"
                                        :disabled="shoufeileixin || actiState == '1' || actiState == '2' || actiState == '3'"
                        >
                        </el-time-picker>-->
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-form-item label="服务年龄" prop="minAge">
                        <el-input maxlength="3" show-word-limit clearable size="small" min="0"
                                  v-model="ruleForm.minAge"
                                  placeholder="年龄" style="width: 50%" class="m_r1"></el-input>
                        岁（含）以上
                    </el-form-item>
                </el-col>
                <el-col :span="9">
                    <el-form-item prop="maxAge" label-width="0px">
                        <el-input maxlength="3" show-word-limit clearable size="small"
                                  min="0" v-model="ruleForm.maxAge"
                                  placeholder="年龄" style="width: 42%" class="m_r1"></el-input>
                        岁（含）以下
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="报名须知" prop="applyNotice">
                    <div class="p_l2" v-html="ruleForm.applyNotice" v-if="editOrNot==0"></div>
                    <Editor :editortext="ruleForm.applyNotice" v-else ref="editor"
                            @changeHtml="noticeGetEditor"></Editor>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="活动内容" prop="actiContent">
                    <div class="p_l2" v-html="ruleForm.actiContent" v-if="editOrNot==0"></div>
                    <Editor :editortext="ruleForm.actiContent" v-else ref="editor"
                            @changeHtml="contentGetEditor"></Editor>
                </el-form-item>
            </el-row>
        </el-form>
        <div class="textBox" v-if="activityselect==0">
            <!--查看页面-->
            <el-button v-if="hasPermission('activity:releaseActivities:adopt') && editOrNot==1" type="primary"
                       size="small" @click="reject(1)" v-noMoreClick>通过
            </el-button>
            <el-button v-if="hasPermission('activity:releaseActivities:reject') && editOrNot==1" type="danger"
                       size="small" @click="reject(2)" v-noMoreClick>驳回
            </el-button>
            <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview') && editOrNot==1"
                       type="primary"
                       size="small" @click="preview(1,actiState)" v-noMoreClick>保存
            </el-button>
            <el-button type="primary" size="small" @click="reject(0)" v-noMoreClick>返回</el-button>
        </div>

        <div class="textBox" v-if="activityselect==2">
            <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview') && editOrNot==1"
                       type="primary"
                       size="small" @click="preview(1,actiState)" v-noMoreClick>保存
            </el-button>
            <el-button type="primary" size="small" @click="reject(0)" v-noMoreClick>返回</el-button>
        </div>

        <div class="textBox" v-if="activityselect==3">
            <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview') && editOrNot==1"
                       type="primary"
                       size="small" @click="preview(1,actiState)" v-noMoreClick>保存
            </el-button>
            <el-button type="primary" size="small" @click="reject(0)" v-noMoreClick>返回</el-button>
        </div>

        <!-- 发布活动-->
        <div class="textBox" v-if="activityselect==1">
            <el-button v-if="hasPermission('activity:releaseActivities:submit')" type="primary"
                       size="small" @click="reject(4)" v-noMoreClick>提交
            </el-button>
            <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview')" type="primary"
                       size="small" @click="preview(1)" v-noMoreClick>保存
            </el-button>
            <el-button type="primary" size="small" @click="reject(0)" v-noMoreClick>返回</el-button>
        </div>

        <!-- 发布活动-->
        <div class="textBox" v-if="activityselect==4">
            <!--<el-button v-if="hasPermission('activity:releaseActivities:release') && editOrNot==0" type="primary"
                       size="small" @click="reject(3)">发布
            </el-button>-->
            <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview') && editOrNot==1"
                       type="primary"
                       size="small" @click="preview(1,actiState)" v-noMoreClick>保存
            </el-button>
            <el-button type="primary" size="small" @click="reject(0)">返回</el-button>
        </div>
    </div>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本上传组件
    import ImgUpload from "@/components/imgUpload/imgUpload"; // 导入图片上传组件
    export default {
        name: "releaseActivities",
        components: {Editor, ImgUpload},
        data() {
            return {
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                switchxuans: '0',
                qrcode: '',//二维码地址
                editOrNot: null,//是否编辑
                actiState: '',//当前活动状态
                tanchuan: false,
                activityselect: 1,//0,1,2,3
                ruleForm: {
                    actiType: '0',
                    name: '',  //活动名称
                    broadcastName: '', //直播名称 ---
                    convention: '0', //活动类型
                    actiServiceObj: '', //活动类别
                    series: '', //系列活动 ---
                    activityLabel: '',//活动标签 ---
                    address: '', //活动地点
                    imgUrl: '',
                    top: '0', //置顶
                    officialWebsitePush: '1', //推送至官网
                    hfivePush: '1', //推送至H5
                    // gzhPush: '0', //是否微信消息推送
                    isAddress: '0', //地址采集 --
                    productionPush: '0', //作品收集
                    isApply: '1',//是否允许报名
                    chargeType: '0', //收费类型
                    price: 0, //价格
                    isPromotion: '0', //是否开启促销
                    promotionPrice: '', //促销价
                    promotionPeriod: '',//促销时段
                    startPromotion: '', //促销时间
                    endtPromotion: '',
                    ageObject: '0', //服务对象
                    minAge: '', //最小年龄
                    maxAge: '', //最大年龄
                    applyNotice: '', //报名须知
                    actiContent: '', //活动内容
                    seriesId: '',//系列id
                    labelId: '',//标签id
                    open: '', //是否公开
                    cardType: [],//报名方式
                    //活动时间段
                    activityCourseSession: [],
                    activityConventionCourseSession: [],
                },
                timePeriod: {
                    actuvutyTotall: null,//总时间
                    startTime: '', //开始时间
                    endTime: '',   //结束时间
                    appointmentPeriod: null,//预约时段
                    ppointmentStartTime: '', //预约开始时间
                    ppointmentEndTime: '', //预约截止时间
                    outTicketTime: '',  //退票截止时间
                    minPeople: '',  //最少人数
                    maxPeople: '', //最多人数
                    reservePeople: 0,//内部预留名额
                },
                rules: {
                    name: [{
                        required: true, message: '请输入活动名称', trigger: 'blur'
                    }],
                    broadcastName: [{
                        required: true, message: '请输入活动名称', trigger: 'blur'
                    }],
                    convention: [
                        {required: true, message: '请选择活动类型', trigger: 'change'}
                    ],
                    actiServiceObj: [
                        {required: true, message: '请选择活动类别', trigger: 'change'}
                    ],
                    series: [{
                        required: true, message: '请选择活动系列', trigger: 'change'
                    }],
                    address: [{
                        required: true, message: '请输入活动地点', trigger: 'blur'
                    }],
                    imgUrl: [{
                        required: true, message: '请上传活动图片', trigger: 'blur'
                    }],
                    chargeType: [{
                        required: true, message: '请选择收费类型', trigger: 'blur'
                    }],
                    price: [
                        {
                            required: true, message: '请输入价格', trigger: 'blur'
                        },
                        {
                            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: '请输入正确的价格'
                        },
                    ],
                    promotionPrice: [
                        {
                            required: true, message: '请输入价格', trigger: 'blur'
                        },
                        {
                            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: '请输入正确的价格'
                        },
                    ],
                    endtPromotion: [{
                        required: true, message: '请选择结束时间段', trigger: 'change'
                    }],
                    ageObject: [{
                        required: true, message: '选择服务对象', trigger: 'change'
                    }],
                    minAge: [{
                        required: true, message: '最小年龄', trigger: 'blur'
                    },
                        {pattern: /^[0-9]*[1-9][0-9]*$/, message: '最小年龄必须为整数'}],
                    maxAge: [{
                        required: true, message: '最大年龄', trigger: 'blur'
                    },
                        {pattern: /^[0-9]*[1-9][0-9]*$/, message: '最大年龄必须为整数'}],
                    applyNotice: [{
                        required: true, message: '请输入报名须知', trigger: 'blur'
                    }],
                    actiContent: [{
                        required: true, message: '请输入活动内容', trigger: 'blur'
                    }],
                },
                options: [ //收费类型选择
                    {
                        value: '1',
                        label: '收费'
                    }, {
                        value: '0',
                        label: '免费'
                    },],
                uploadRequire: {
                    //上传图片要求
                    imgCount: 1, // 上传数量 0表示不限制数量
                    imgList: [], // 已上传的文件
                    uploadPath: "course",
                    imgSize: '750*480', // 图片尺寸
                    imgNum: 10, // 上传文件兆数 0表示不限兆数
                    pictureSize: true, //是否开启验证图片尺寸
                },
                id: '',//修改活动的id

                //弹窗
                seriesActivities: false,//系列活动、标签管理的弹窗
                series: 0,// 1为活动系列、2为活动标签弹窗
                choice: 1,

                //免费时禁用价格
                shoufeileixin: false,
                guanbishoufei: false,
            }
        },
        watch: {
            $route: {
                handler() {
                    if (this.$route.query.id) {
                        this.id = this.$route.query.id
                        this.getActivityDetails(this.id)
                        this.editOrNot = this.$route.query.num; //0查看 1编辑
                        this.actiState = this.$route.query.actiState;
                        if (this.actiState == '0') { //草稿
                            this.activityselect = 1;
                        } else if (this.actiState == '1') { //未开始
                            this.activityselect = 2
                        } else if (this.actiState == '3' || this.actiState == '2') { //已结束 进行中
                            this.activityselect = 3;
                        } else if (this.actiState == '6') { //待发布
                            this.activityselect = 4;
                        } else if (this.actiState == '5') { //评审中
                            this.activityselect = 0;
                        }
                    }
                },
                deep: true,
                immediate: true
            },
            'ruleForm.chargeType': {
                handler() {
                    if (this.ruleForm.chargeType == 0) {
                        this.switchxuans = 0;
                        this.ruleForm.isPromotion = '0';
                        this.ruleForm.price = 0;
                        this.ruleForm.promotionPrice = '';
                        this.ruleForm.promotionPeriod = '';
                        this.ruleForm.startPromotion = '';
                        this.ruleForm.endtPromotion = '';
                        this.shoufeileixin = true;
                    } else {
                        this.shoufeileixin = false;
                        // this.ruleForm.isPromotion = 0;
                    }
                },
                deep: true,
                immediate: true
            },
            'ruleForm.productionPush': {
                handler() {
                    if (this.ruleForm.productionPush == 1) {
                        this.ruleForm.chargeType = '0';
                        this.ruleForm.isPromotion = 0;
                        this.ruleForm.price = 0;
                        this.ruleForm.promotionPrice = '';
                        this.ruleForm.promotionPeriod = '';
                        this.ruleForm.startPromotion = '';
                        this.ruleForm.endtPromotion = '';
                        this.guanbishoufei = true;
                    } else {
                        this.guanbishoufei = false;
                    }
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.addTimePeriod()
        },
        methods: {
            // 活动类型切换
            changeServiceType() {
                this.ruleForm.activityCourseSession = []
                this.addTimePeriod()
            },
            //判断预约时段在不在系列时段中
            judgmentPeriod() {
                if (this.ruleForm.series.length != 0) {
                    this.ruleForm.activityCourseSession.map(item => {
                        if (item.appointmentPeriod) {
                            if (this.timeRatioSize(this.ruleForm.series.startTime, item.appointmentPeriod[0]) && this.timeRatioSize(item.appointmentPeriod[1], this.ruleForm.series.endTime)) {
                            } else {
                                this.$message.warning('场次预约时间不在活动系列时间内')
                            }
                        }
                    })
                }
            },

            //促销状态改变
            PromotionStatusChange() {
                if (this.switchxuans == 0) {
                    this.ruleForm.promotionPrice = '';
                    this.ruleForm.promotionPeriod = '';
                    this.ruleForm.startPromotion = '';
                    this.ruleForm.endtPromotion = '';
                    this.ruleForm.isPromotion = '0';
                } else {
                    this.ruleForm.isPromotion = '1';
                }
            },

            //判断两个时间的大小
            timeRatioSize(date, date2) {
                var date = new Date(date);
                var date1 = new Date(date2);
                if (date.getTime() - date1.getTime() <= 0) {
                    return true;
                } else
                    return false;
            },

            //活动时间段选中事件
            activeTimePeriod(num, item) {
                let {actuvutyTotall, appointmentPeriod} = item
                if (!actuvutyTotall) {
                    return
                }
                let startTime = actuvutyTotall[0] || ''
                let endTime = actuvutyTotall[1] || ''
                if (new Date(startTime) <= new Date() || new Date(endTime) <= new Date()) {
                    this.$message.warning('活动时间不得小于等于当前时间')
                    this.$set(item, 'actuvutyTotall', null)
                    return
                }
                if (new Date(startTime) >= new Date(endTime)) {
                    this.$message.warning('活动开始时间不得大于等于活动结束时间')
                    this.$set(item, 'actuvutyTotall', null)
                    return
                }
                let appointmentEndTime = appointmentPeriod != null ? appointmentPeriod[1] : ''
                if (appointmentEndTime != '' && new Date(startTime) < new Date(appointmentEndTime)) {
                    this.$message.warning('活动开始时间不得小于预约结束时间')
                    this.$set(item, 'actuvutyTotall', null)
                    return
                }
                this.ruleForm.activityCourseSession[num].startTime = startTime
                this.ruleForm.activityCourseSession[num].endTime = endTime
            },
            //预约时段选中
            activeappointmentPeriod(num, item) {
                if (this.ruleForm.seriesId) {
                    this.judgmentPeriod();
                }
                let {actuvutyTotall, appointmentPeriod} = item
                if(this.ruleForm.convention == 0) {
                    this.$set(item, 'outTicketTime', '')
                }
                if (!appointmentPeriod) {
                    return
                }
                let startTime = appointmentPeriod[0] || ''
                let endTime = appointmentPeriod[1] || ''
                if (new Date(startTime) <= new Date() || new Date(endTime) <= new Date()) {
                    this.$message.warning('预约时间不得小于等于当前时间')
                    this.$set(item, 'appointmentPeriod', null)
                    return
                }
                if (new Date(startTime) >= new Date(endTime)) {
                    this.$message.warning('预约开始时间不得大于等于预约结束时间')
                    this.$set(item, 'appointmentPeriod', null)
                    return
                }
                let actStartTime = actuvutyTotall != null ? actuvutyTotall[0] : ''
                if (actStartTime != '' && new Date(endTime) > new Date(actStartTime)) {
                    this.$message.warning('预约结束时间不得大于活动开始时间')
                    this.$set(item, 'appointmentPeriod', null)
                    return
                }
                this.ruleForm.activityCourseSession[num].ppointmentStartTime = startTime
                this.ruleForm.activityCourseSession[num].ppointmentEndTime = endTime
            },
            // 退票截止时间
            outTicketTimeChange(item) {
                console.log(item);
                let {outTicketTime, appointmentPeriod} = item
                if (!outTicketTime) {
                    return
                }
                let startTime = appointmentPeriod[1] || ''
                if (outTicketTime != '' && new Date(outTicketTime) < new Date(startTime)) {
                    this.$message.warning('退票截止时间不得小于预约时间')
                    this.$set(item, 'outTicketTime', '')
                    return
                }
            },

            // 最少人数
            peopleChange(item) {
                let {minPeople, maxPeople} = item
                if (parseInt(minPeople) > parseInt(maxPeople)) {
                    this.$message.warning('最少人数必须小于等于最大人数')
                }
            },

            //促销时段选择
            activePromotionPeriod(item) {
                this.ruleForm.startPromotion = item[0];
                this.ruleForm.endtPromotion = item[1];
            },

            //获取活动详情
            getActivityDetails(id) {
                this.$axios(this.api.activity.activityDetails + id, {}, 'get').then((res) => {
                    if (res.status) {
                        this.ruleForm = this.recover(this.ruleForm, res.data);
                        if(res.data.convention == 1) {
                            this.ruleForm.activityCourseSession = res.data.activityConventionCourseSession
                        } else {
                            this.ruleForm.activityCourseSession = res.data.activityCourseSession;
                        }
                        // Object.assign(this.ruleForm, res.data)
                        this.ruleForm.activityCourseSession.map(item => {
                            this.$set(item, 'actuvutyTotall', [item.startTime, item.endTime]);
                            this.$set(item, 'appointmentPeriod', [item.ppointmentStartTime, item.ppointmentEndTime]);
                        })
                        this.ruleForm.isPromotion = res.data.isPromotion;
                        if (this.ruleForm.startPromotion) {
                            this.ruleForm.promotionPeriod = [this.ruleForm.startPromotion, this.ruleForm.endtPromotion];
                        }
                        this.uploadRequire.imgList.push({name: '图片', url: res.data.imgUrl});
                        this.ruleForm.imgUrl = res.data.imgUrl;
                        if (this.ruleForm.isPromotion == 2 || this.ruleForm.isPromotion == 3 || this.ruleForm.isPromotion == 1) {
                            this.switchxuans = '1';
                        }
                    }
                })
            },

            //打开弹窗标题 1为活动系列、2为活动标签弹窗
            popup(num) {
                this.series = num;
                this.seriesActivities = true;
            },

            //关闭弹窗
            closed() {
                this.seriesActivities = false;
            },

            //弹窗选择
            select(row) {
                if (this.series == 1) {
                    this.ruleForm.series = row;
                    this.ruleForm.seriesId = row.id;
                    if (this.ruleForm.activityCourseSession[0].appointmentPeriod[0]) {
                        this.judgmentPeriod();
                    }
                } else if (this.series == 2) {
                    this.ruleForm.activityLabel = row;
                    this.ruleForm.labelId = row.id;
                }
            },

            // 获取上传图片数据
            getImgList(data) {
                console.log(data);
                if (data.length == 0) {
                    this.ruleForm.imgUrl = ""
                } else {
                    this.ruleForm.imgUrl = data[0].imgUrl
                    this.$refs.ruleForm.clearValidate('imgUrl');
                }
            },

            //删除活动序列
            seriesDeletion(num) {
                if (num == 1) {
                    this.ruleForm.series = '';
                    this.ruleForm.seriesId = '';
                } else {
                    this.ruleForm.activityLabel = '';
                    this.ruleForm.labelId = '';
                }
            },

            // 获取富文本数据(报名须知)
            noticeGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.applyNotice = data;
            },

            // 获取富文本数据(活动内容)
            contentGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.actiContent = data;
            },

            //增加时间段
            addTimePeriod() {
                let obj = JSON.parse(JSON.stringify(this.timePeriod))
                this.ruleForm.activityCourseSession.push(obj);
                console.log(this.ruleForm);
            },

            //删除时间段
            deleteTimePeriod(index) {
                this.$confirm(`确定删除所选项吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.ruleForm.activityCourseSession.splice(index, 1);
                });
            },

            // 触发表单验证(num1为提交，2为保存草稿） ,
            submitForm(formName, num, actState) {
                if (this.ruleForm.price > 9999) {
                    this.$message.error('收费价格不得高于9999元')
                    return;
                }
                if (this.ruleForm.chargeType == 1 && this.ruleForm.price < 0.01) {
                    this.$message.error('收费价格不得低于0.01元')
                    return;
                }
                if (this.switchxuans == 1 && this.ruleForm.promotionPrice < 0.01) {
                    this.$message.error('促销价格不得低于0.01元')
                    return;
                }
                if (this.switchxuans == 1 && this.ruleForm.promotionPrice >= this.ruleForm.price) {
                    this.$message.error('促销价格不得高于等于收费价格')
                    return;
                }
                if (parseInt(this.ruleForm.minAge) > parseInt(this.ruleForm.maxAge)) {
                    this.$message.error('年龄下限不得小于年龄上限')
                    return;
                }
                if (this.ruleForm.activityCourseSession.length == 0) {
                    this.$message.success('活动场次信息为空，请创建活动场次')
                } else {
                    let actiState = {};
                    if (num == 1) {
                        actiState = '5';
                    } else {
                        actiState = actState;
                    }
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let param = JSON.parse(JSON.stringify(this.ruleForm))
                            console.log(param);
                            if(param.convention == 1) {
                                param.activityConventionCourseSession = JSON.parse(JSON.stringify(param.activityCourseSession))
                                param.activityCourseSession = []
                            } else {
                                param.activityConventionCourseSession = []
                            }
                            param.maxAge = parseInt(param.maxAge);
                            param.minAge = parseInt(param.minAge);
                            param.activityCourseSession.map((item) => {
                                item.minPeople = parseInt(item.minPeople);
                                item.maxPeople = parseInt(item.maxPeople);
                                if (item.reservePeople == '') {
                                    item.reservePeople = 0;
                                }
                            })
                            if (this.id == '') { //新增活动
                                this.$axios(this.api.activity.newActivities, {
                                    actiState,
                                    ...param
                                }, 'post').then((res) => {
                                    if (res.status) {
                                        this.$message.success('新增活动成功')
                                        this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                                        this.$router.push({path: '/activity/activityList'})
                                    } else {
                                        this.$message.error(res.msg)
                                    }
                                })
                            } else { //修改活动
                                if (this.activityselect != 2 && this.activityselect != 3) {
                                    this.$axios(this.api.activity.modifyActivity, {
                                        actiState,
                                        id: this.id,
                                        ...param
                                    }, 'put').then((res) => {
                                        if (res.status) {
                                            this.$message.success('保存成功')
                                            this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                                            this.$router.push({path: '/activity/activityList'})
                                        } else {
                                            this.$message.error(res.msg)
                                        }
                                    })
                                } else {
                                    this.$axios(this.api.activity.updateByIdRelease, {
                                        actiState,
                                        id: this.id,
                                        ...param
                                    }, 'put').then((res) => {
                                        if (res.status) {
                                            this.$message.success('保存成功')
                                            this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                                            this.$router.push({path: '/activity/activityList'})
                                        } else {
                                            this.$message.error(res.msg)
                                        }
                                    })
                                }
                                let tabIndex = this.$route.query.tabIndex || '0'
                                sessionStorage.setItem('activityListTabIndex', tabIndex)
                            }
                        } else {
                            return false;
                        }
                    });
                }
            },

            //通过，驳回请求接口
            afaffagffrfweg(num) {
                if (this.ruleForm.chargeType == 1 && this.ruleForm.price < 0.01) {
                    this.$message.error('收费价格不得低于0.01元')
                    return;
                }
                if (this.switchxuans == 1 && this.ruleForm.promotionPrice < 0.01) {
                    this.$message.error('促销价格不得低于0.01元')
                    return;
                }
                this.$axios(this.api.activity.modifyState, {
                    id: this.id,
                    actiState: num,
                }, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg);
                        this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                        this.$router.push({path: '/activity/activityList'})
                    }
                })
            },


            //0返回，1通过，2驳回，3发布，4提交，
            reject(num) {
                if (num == 2) {
                    this.afaffagffrfweg(0);
                } else if (num == 1) {
                    this.afaffagffrfweg(6);
                } else if (num == 3) {
                    this.afaffagffrfweg(99);
                } else if (num == 4) {
                    this.submitForm('ruleForm', 1)
                } else if (num == 0) {
                    let tabIndex = this.$route.query.tabIndex || '0'
                    sessionStorage.setItem('activityListTabIndex', tabIndex)
                    this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                    this.$router.push({path: '/activity/activityList'})
                }
            },

            //0预览,1保存并预览 , 对前活动状态
            preview(num, actState) {
                if (this.ruleForm.chargeType == 1 && this.ruleForm.price < 0.01) {
                    this.$message.error('收费价格不得低于0.01元')
                    return;
                }
                if (this.switchxuans == 1 && this.ruleForm.promotionPrice < 0.01) {
                    this.$message.error('促销价格不得低于0.01元')
                    return;
                }
                if (num == 0) {
                } else {
                    if (actState) {
                        this.submitForm('ruleForm', 2, this.actiState)
                    } else {
                        this.submitForm('ruleForm', 2, 0)
                    }
                }
            },

            handleChangeTop() {
                if (!this.ruleForm.top) {
                    setTimeout(() => {
                        this.ruleForm.top = 1;
                    })
                }
            },
        }
    }
</script>

<style scoped>
</style>
